import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root', // This makes the service available throughout the app
})
export class CookieStorageService {
  setItem(key: string, value: string) {
    document.cookie = `${key}=${value}; path=/; secure; samesite=strict`;
  }

  getItem(key: string) {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${key.replace(/([.$?*|{}()[]\/+^])/g, '\\$1')}=([^;]*)`
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  removeItem(key: string) {
    document.cookie = `${key}=; path=/; max-age=-1`;
  }

  clear() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=; path=/; max-age=-1';
    }
  }
}
