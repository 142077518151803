import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { from } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((user) => {
        return true;
      }),
      catchError((error) => {
        console.log('Auth Guard error:', error);
        return of(false);
      }),
    );
  }

  public getToken(): Observable<any> {
    return from(Auth.currentSession()).pipe(
      map((session: CognitoUserSession) => session.getIdToken().getJwtToken()),
      catchError(() => {
        return of(null);
      }),
    );
  }

  public getAdGroups(): Observable<string[] | any> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((currentUser: any) => {
        console.log('currentUser',currentUser)
        const adGroups: string = currentUser.signInUserSession?.idToken?.payload['custom:azureAdGroups'];

        // Split and clean up the adGroups string
        const cleanedGroups = adGroups
          .replace('[', '')  // Remove opening bracket
          .replace(']', '')  // Remove closing bracket
          .split(',')        // Split by comma
          .map((group) => group.trim());  // Trim whitespace

        // Final transformation: Split by underscore and return part after it
        const finalGroups = cleanedGroups.map((group) => group.split('_').pop() as string);
        return finalGroups;
      }), catchError((err: any) => {
        console.log(
          'currentUser error',
          err,
        );
        return err
      })
    );
  }
}