const hasLocalhost = (hostname: string) =>
  Boolean(
    hostname.match(/localhost/) ||
      hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/),
  );
const isLocalhost = hasLocalhost(window.location.hostname);
export const environment = {
  keyKeeperApiUrl: 'https://cm9ypqafw7.execute-api.eu-west-1.amazonaws.com/dev',
  stage: isLocalhost ? 'local' : 'dev',
  production: false,
  amplify: {
    Auth: {
      identityPoolRegion: 'eu-west-1',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_6CRK668dz',
      userPoolWebClientId: '3tnh3gvrps3q9jiuvps0o0dlc',
      oauth: {
        domain: 'drx-keykeeper-dev.auth.eu-west-1.amazoncognito.com',
        scope: [
          'phone',
          'email',
          'openid',
          'profile',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: isLocalhost
          ? 'http://localhost:4200/secure'
          : 'https://key-keeper.dev.shopfloor-sensitive-data.draexlmaier.solutions/secure',
        redirectSignOut: isLocalhost
          ? 'http://localhost:4200/public'
          : 'https://key-keeper.dev.shopfloor-sensitive-data.draexlmaier.solutions/public',

        responseType: 'code',
      },
    },
  },
};
