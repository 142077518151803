import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from './environments/environment';
import { CookieStorageService } from './app/secure/services/cookie-storage.service';


const cookieStorageService = new CookieStorageService();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

Amplify.configure({
  ...environment.amplify,  
  Auth: {
    ...environment.amplify.Auth,  
    storage: cookieStorageService, 
  }
});

