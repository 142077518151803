import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Auth } from '@aws-amplify/auth';
import { environment } from './environments/environment';
import { CookieStorageService } from './app/secure/services/cookie-storage.service';


const cookieStorageService = new CookieStorageService();


Auth.configure({
  region: environment.amplify.region,
  userPoolId: environment.amplify.userPoolId,
  userPoolWebClientId: environment.amplify.userPoolWebClientId,
  mandatorySignIn: true,
  oauth: {
    domain:
      environment.amplify.oauth.domain +
      '.auth.' +
      environment.amplify.region +
      '.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: environment.amplify.oauth.redirectSignIn,
    redirectSignOut: environment.amplify.oauth.redirectSignOut,
    responseType: 'code',
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

